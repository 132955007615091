<template>
  <div class="returns"><returns-report /></div>
</template>

<script>
import ReturnsReport from "@/components/Reports/Sales/ReturnsReport.vue";

export default {
  name: "Returns",
  components: { ReturnsReport },
};
</script>